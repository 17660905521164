export const routes: { [key: string]: string } = {
  root: "/",
  login: "/login",
  forgotPassword: "/forgot-password",
  verify: "/verify-code",
  resetPassword: "/reset-password",
  profile: "/profile",
  editProfile: "/profile",
  roleList: "/roles",
  addRole: "/roles/add",
  editRole: "/roles/editRole",
  privilige: "/roles/privilige",
  users: "/users",
  addUser: "/users/add",
  agentList: "/agents",
  addAgent: "/agents/add",
  editAgent: "/agents/editAgent",
  newTicket: "/tickets/addTicket",
  editTicket: "/tickets/edit",
  ticket: "/tickets",
  ticketSource: "/ticketSource",
  addTicketSource: "/ticketSource/add",
  editTicketSource: "/ticketSource/edit",
  ticketSubSource: "/ticketSubSource",
  addTicketSubSource: "/ticketSubSource/add",
  editTicketSubSource: "/ticketSubSource/edit",
  keyAttributes: "/keyAttributes",
  addKeyAttributes: "/keyAttributes/add",
  editKeyAttributes: "/keyAttributes/edit",
  childAttributes: "/childAttributes",
  addChildAttributes: "/childAttributes/add",
  editChildAttributes: "/childAttributes/edit",
  assignGroups: "/assignGroups",
  addAssignGroups: "/assignGroups/add",
  editAssignGroups: "/assignGroups/edit",
  ticketCategories: "/ticketCategories",
  addTicketCategories: "/ticketCategories/add",
  editTicketCategories: "/ticketCategories/edit",
  ticketSubCategories: "/ticketSubCategories",
  addTicketSubCategories: "/ticketSubCategories/add",
  editTicketSubCategories: "/ticketSubCategories/edit",
  issueTypes: "/issueTypes",
  addIssueTypes: "/issueTypes/add",
  editIssueTypes: "/issueTypes/edit",
  ticketTypes: "/ticketTypes",
  addTicketTypes: "/ticketTypes/add",
  editTicketTypes: "/ticketTypes/edit",
  dashboard: "/dashboard",
  ticketStatus: "/ticketStatus",
  addTicketStatus: "/ticketStatus/add",
  editTicketStatus: "/ticketStatus/edit",
  addSubCategoriesMapping:'/ticketSubCategories/mapping'
};
