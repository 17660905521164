import { FC, useCallback, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import { Grid, Paper, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Authenticated } from "../utils/redux";
import Login from "../screens/Authentication/Login";
import ForgotPassword from "../screens/Authentication/ForgotPassword";
import ResetPin from "../screens/Authentication/ResetPin";
import UserProfile from "../screens/Authenticated/UserProfile";
import AuthenticatedLayout from "./AuthenticatedLayout";
import { routes } from "../utils/constants/routes";
import RoleList from "../screens/Authenticated/Role/RoleList/RoleList";
import AgentList from "../screens/Authenticated/Agents/AgentList";
import AddEditAgents from "../screens/Authenticated/Agents/NewAgent/NewAgent";
import AddEditRole from "../screens/Authenticated/Role/RoleList/AddEditRole/AddEditRole";
import Privilege from "../screens/Authenticated/Role/RoleList/Privilege";
import UserManagement from "../screens/Authenticated/UserManagement";
import AddUser from "../screens/Authenticated/AddUser";
import Verify from "../screens/Authentication/VerifyCode";
import EditProfile from "../screens/Authenticated/UserProfile/EditProfile";
import strings from "../common/Translation/Translate";
import axiosInstance from "../utils/axios";
import { URLS } from "../utils/constants/urls";
import {
  setLanguageList,
  setTranslation,
} from "../utils/redux/reducer/authentication-slice";
import TicketList from "../screens/Authenticated/Ticket/Ticket/TicketList";
import AddEditTicket from "../screens/Authenticated/Ticket/Ticket/AddEditTicket";
// import NewTicket from "../screens/Authenticated/Agents/NewAgent/NewAgent";
import TicketSourceList from "../screens/Authenticated/AdminScreens/TicketSource/TicketSourceList/TicketSourceList";
import AddEditTicketSource from "../screens/Authenticated/AdminScreens/TicketSource/TicketSourceList/AddEditTicketSource";
import TicketSubSourceList from "../screens/Authenticated/AdminScreens/TicketSubSource/TicketSubSourceList/TicketSubSourceList";
import AddEditTicketSubSource from "../screens/Authenticated/AdminScreens/TicketSubSource/TicketSubSourceList/AddEditTicketSubSource";
import KeyAttributesList from "../screens/Authenticated/AdminScreens/KeyAttributes/KeyAttributesList/KeyAttributesList";
import AddEditKeyAttributes from "../screens/Authenticated/AdminScreens/KeyAttributes/KeyAttributesList/AddEditKeyAttributes";
import ChildAttributesList from "../screens/Authenticated/AdminScreens/ChildAttributes/ChildAttributesList/ChildAttributesList";
import AddEditChildAttributes from "../screens/Authenticated/AdminScreens/ChildAttributes/ChildAttributesList/AddEditChildAttributes";
import AssignGroupsList from "../screens/Authenticated/AdminScreens/AssignGroups/AssignGroupsList/AssignGroupList";
import AddEditAssignGroups from "../screens/Authenticated/AdminScreens/AssignGroups/AssignGroupsList/AddEditAssignroups";
import TicketCategoriesList from "../screens/Authenticated/AdminScreens/TicketCategories/TicketCategoriesList/TicketCategoriesList";
import AddEditTicketCategories from "../screens/Authenticated/AdminScreens/TicketCategories/TicketCategoriesList/AddEditTicketCategories";
import TicketSubCategoriesList from "../screens/Authenticated/AdminScreens/TicketSubCategories/TicketSubCategoriesList/TicketSubCategoriesList";
import AddEditTicketSubCategories from "../screens/Authenticated/AdminScreens/TicketSubCategories/TicketSubCategoriesList/AddEditTicketSubCategories";
import IssueTypesList from "../screens/Authenticated/AdminScreens/IssueTypes/IssueTypesList/IssueTypesList";
import AddEditIssueTypes from "../screens/Authenticated/AdminScreens/IssueTypes/IssueTypesList/AddEditIssueTypes/AddEditIssueTypes";
import TicketTypesList from "../screens/Authenticated/AdminScreens/TicketTypes/TicketTypesList/TicketTypesList";
import AddEditTicketTypes from "../screens/Authenticated/AdminScreens/TicketTypes/TicketTypesList/AddEditTicketTypes/AddEditTicketTypes";
import Dashboard from "../screens/Authenticated/Dashboard/Dashboard";
import TicketStatusList from "../screens/Authenticated/AdminScreens/TicketStatus/TicketStatusList/TicketStatusList";
import AddEditTicketStatus from "../screens/Authenticated/AdminScreens/TicketStatus/TicketStatusList/AddEditTicketStatus/AddEditTicketStatus";
import AddTicketSubCategoriesMapping from "../screens/Authenticated/AdminScreens/TicketSubCategories/TicketSubCategoriesList/AddEditTicketSubCategories/AddTicketSubCategoriesMapping";

const Root: FC = () => {
  let location = useLocation();

  const isLogin = useSelector(Authenticated);

  const { from } = location.state || { from: { pathname: routes.dashboard } };
  return isLogin ? <Navigate to={from} /> : <Navigate to={routes.login} />;
};

const AuthenticatedScreens: FC<{ Component: FC }> = ({ Component }) => {
  const isLogin = useSelector(Authenticated);

  return isLogin ? (
    <AuthenticatedLayout Component={Component} />
  ) : (
    <Navigate to={routes.login} />
  );
};

const Routes: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const getTranslation = useCallback(async () => {
    const { status, data } = await axiosInstance.get(URLS.tarnslation("en_IN"));
    if (status === 200) {
      dispatch(setTranslation(data));
      strings.setContent({
        en_IN: data,
      });
    }
  }, [dispatch]);
  const getLanguageList = useCallback(async () => {
    const { status, data } = await axiosInstance.get(URLS.language);
    if (status === 200) {
      const lang = data.map((item: any) => ({
        text: item.text,
        value: item.value,
      }));
      dispatch(setLanguageList(lang));
    }
  }, [dispatch]);
  useEffect(() => {
    getTranslation();
    getLanguageList();
  }, [getTranslation, getLanguageList]);
  return (
    <Grid item container flexDirection="column">
      <Grid className="pageWrapper" item sx={{ width: "100%" }}>
        <Paper
          sx={{ background: theme.palette.secondary.light, minHeight: "100vh" }}
          elevation={0}
          square={false}
          className="paperWrapper"
        >
          <Switch>
            <Route path={routes.root} element={<Root />} />
            <Route path={routes.login} element={<Login />} />
            <Route path={routes.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.verify} element={<Verify />} />
            <Route path={routes.resetPassword} element={<ResetPin />} />
            <Route
              path={routes.addRole}
              element={<AuthenticatedScreens Component={AddEditRole} />}
            />
            <Route
              path={`${routes.editRole}/:id`}
              element={<AuthenticatedScreens Component={AddEditRole} />}
            />
            <Route
              path={`${routes.privilige}/:id`}
              element={<AuthenticatedScreens Component={Privilege} />}
            />
            <Route
              path={routes.roleList}
              element={<AuthenticatedScreens Component={RoleList} />}
            />
            <Route
              path={`${routes.profile}/:id`}
              element={<AuthenticatedScreens Component={EditProfile} />}
            />
            <Route
              path={routes.profile}
              element={<AuthenticatedScreens Component={UserProfile} />}
            />
            <Route
              path={routes.users}
              element={<AuthenticatedScreens Component={UserManagement} />}
            />
            <Route
              path={routes.addUser}
              element={<AuthenticatedScreens Component={AddUser} />}
            />
            <Route
              path={`${routes.users}/:id`}
              element={<AuthenticatedScreens Component={AddUser} />}
            />
            <Route
              path={routes.agentList}
              element={<AuthenticatedScreens Component={AgentList} />}
            />
            <Route
              path={routes.addAgent}
              element={<AuthenticatedScreens Component={AddEditAgents} />}
            />
            <Route
              path={`${routes.editAgent}/:id`}
              element={<AuthenticatedScreens Component={AddEditAgents} />}
            />

            <Route
              path={routes.ticketSource}
              element={<AuthenticatedScreens Component={TicketSourceList} />}
            />
            <Route
              path={routes.addTicketSource}
              element={<AuthenticatedScreens Component={AddEditTicketSource} />}
            />
            <Route
              path={`${routes.editTicketSource}/:id`}
              element={<AuthenticatedScreens Component={AddEditTicketSource} />}
            />

            <Route
              path={routes.ticket}
              element={<AuthenticatedScreens Component={TicketList} />}
            />
            <Route
              path={routes.newTicket}
              element={<AuthenticatedScreens Component={AddEditTicket} />}
            />
            <Route
              path={`${routes.editTicket}/:id`}
              element={<AuthenticatedScreens Component={AddEditTicket} />}
            />
            <Route
              path={routes.ticketSubSource}
              element={<AuthenticatedScreens Component={TicketSubSourceList} />}
            />
            <Route
              path={routes.addTicketSubSource}
              element={
                <AuthenticatedScreens Component={AddEditTicketSubSource} />
              }
            />
            <Route
              path={`${routes.editTicketSubSource}/:id`}
              element={
                <AuthenticatedScreens Component={AddEditTicketSubSource} />
              }
            />
            <Route
              path={routes.keyAttributes}
              element={<AuthenticatedScreens Component={KeyAttributesList} />}
            />
            <Route
              path={routes.addKeyAttributes}
              element={
                <AuthenticatedScreens Component={AddEditKeyAttributes} />
              }
            />
            <Route
              path={`${routes.editKeyAttributes}/:id`}
              element={
                <AuthenticatedScreens Component={AddEditKeyAttributes} />
              }
            />
            <Route
              path={routes.childAttributes}
              element={<AuthenticatedScreens Component={ChildAttributesList} />}
            />
            <Route
              path={routes.addChildAttributes}
              element={
                <AuthenticatedScreens Component={AddEditChildAttributes} />
              }
            />
            <Route
              path={`${routes.editChildAttributes}/:id`}
              element={
                <AuthenticatedScreens Component={AddEditChildAttributes} />
              }
            />
            <Route
              path={routes.assignGroups}
              element={<AuthenticatedScreens Component={AssignGroupsList} />}
            />
            <Route
              path={routes.addAssignGroups}
              element={<AuthenticatedScreens Component={AddEditAssignGroups} />}
            />
            <Route
              path={`${routes.editAssignGroups}/:id`}
              element={<AuthenticatedScreens Component={AddEditAssignGroups} />}
            />

            <Route
              path={routes.ticketCategories}
              element={
                <AuthenticatedScreens Component={TicketCategoriesList} />
              }
            />
            <Route
              path={routes.addTicketCategories}
              element={
                <AuthenticatedScreens Component={AddEditTicketCategories} />
              }
            />
            <Route
              path={`${routes.editTicketCategories}/:id`}
              element={
                <AuthenticatedScreens Component={AddEditTicketCategories} />
              }
            />
            <Route
              path={routes.ticketSubCategories}
              element={
                <AuthenticatedScreens Component={TicketSubCategoriesList} />
              }
            />
            <Route
              path={routes.addTicketSubCategories}
              element={
                <AuthenticatedScreens Component={AddEditTicketSubCategories} />
              }
            />
            <Route
              path={`${routes.editTicketSubCategories}/:id`}
              element={
                <AuthenticatedScreens Component={AddEditTicketSubCategories} />
              }
            />
            <Route
              path={routes.issueTypes}
              element={<AuthenticatedScreens Component={IssueTypesList} />}
            />
            <Route
              path={routes.addIssueTypes}
              element={<AuthenticatedScreens Component={AddEditIssueTypes} />}
            />
            <Route
              path={`${routes.editIssueTypes}/:id`}
              element={<AuthenticatedScreens Component={AddEditIssueTypes} />}
            />
            <Route
              path={routes.ticketTypes}
              element={<AuthenticatedScreens Component={TicketTypesList} />}
            />
            <Route
              path={routes.addTicketTypes}
              element={<AuthenticatedScreens Component={AddEditTicketTypes} />}
            />
            <Route
              path={`${routes.editTicketTypes}/:id`}
              element={<AuthenticatedScreens Component={AddEditTicketTypes} />}
            />
            <Route
              path={routes.dashboard}
              element={<AuthenticatedScreens Component={Dashboard} />}
            />
            <Route
              path={routes.ticketStatus}
              element={<AuthenticatedScreens Component={TicketStatusList} />}
            />
            <Route
              path={routes.addTicketStatus}
              element={<AuthenticatedScreens Component={AddEditTicketStatus} />}
            />
            <Route
              path={`${routes.editTicketStatus}/:id`}
              element={<AuthenticatedScreens Component={AddEditTicketStatus} />}
            />
               <Route
              path={routes.addSubCategoriesMapping}
              element={<AuthenticatedScreens Component={AddTicketSubCategoriesMapping} />}
            />
          </Switch>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Routes;
